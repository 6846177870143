import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title_single: {
    id: 'under_review.title_single',
    defaultMessage: 'There are no credit offers available for the customer(s).*',
  },
  title_coapp: {
    id: 'under_review.title_coApp',
    defaultMessage: 'There are no credit offers available for the customer(s).',
  },
  detail1: {
    id: 'under_review.detail1',
    defaultMessage: 'Your customer(s) will be emailed additional information shortly.',
  },
  detail_single: {
    id: 'under_review.detail_single',
    defaultMessage: '*May Reconsider with Joint Applicant.',
  },
});

export default messages;
