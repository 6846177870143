export const GLOBAL_LIMIT = 10;

export const signatures: { [key: string]: string } = {
  R0lGODdh: 'image/gif',
  R0lGODlh: 'image/gif',
  iVBORw0KGgo: 'image/png',
  PD94bWwgd: 'image/svg+xml',
  PHN2ZyB4: 'image/svg+xml',
  PHN2ZyBp: 'image/svg+xml',
  '/9j/': 'image/jpg',
};
