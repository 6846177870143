import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import Checkbox from '@luna-protocol/core/src/components/Checkbox/Checkbox';
import Input from '@luna-protocol/core/src/components/Input/Input';
import Modal from '@luna-protocol/core/src/components/Modal/Modal';
import SelectInput from '@luna-protocol/core/src/components/SelectInput/SelectInput';
import { useGetDealerBranding } from '@luna-protocol/core/src/queries/useGetDealerBranding.ts';
import { stateCodeMapping } from '@luna-protocol/core/src/utils/constants/states.js';
import { getCustomerIdFromToken } from '@luna-protocol/core/src/utils/getCustomerIdFromToken.ts';
import useToast from '@luna-protocol/core/src/utils/useToast.ts';
import { Form, Formik, FormikErrors } from 'formik';
import { ChangeEvent, SetStateAction, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useSearchParams } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { useSessionStorage } from 'usehooks-ts';
import { AppContext } from '../../AppContext.js';
import { LegalTermType, useGetLegalTerms } from '../../queries/useGetLegalTerms';
import { useGetPrimaryNames } from '../../queries/useGetPrimaryNames.ts';
import { usePostCoapplicantApplication } from '../../queries/usePostCoapplicantApplication.ts';
import { usePostPrimaryApplication } from '../../queries/usePostPrimaryApplication';
import {
  ApplicationFormValues,
  CoapplicantDetails,
  SerialNumberArray,
  UserDetails,
  residenceTypeDisplayValues,
} from '../../types.js';
import { TuTestCases } from '../../utils/constants/applicationFormTestcases.ts';
import { applicationSchema } from '../../utils/constants/applicationSchema.js';
import messages, { modalNames } from './ApplicationForm.messages';
import './ApplicationForm.scss';

const ApplicationForm = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const {
    dealerID,
    setDealerID,
    setCoapplicantID,
    setLoanApplicationID,
    setCustomerID,
    setUserDetails,
    setIsDealerDevice,
    isDealerDevice,
    setIsJoint,
  } = useContext(AppContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [primaryLoanApplicationID, setPrimaryLoanApplicationID] = useState('');
  const [legalTermOpen, setLegalTermOpen] = useState<LegalTermType>('TermsOfUse');
  const [serialNumber, setSerialNumber] = useState<SerialNumberArray>();
  const { data: legalTerms } = useGetLegalTerms();
  const { data: brandName } = useGetDealerBranding(dealerID || '');
  const { data: primaryNames } = useGetPrimaryNames(primaryLoanApplicationID || '');
  const { submitApplication } = usePostPrimaryApplication();
  const { submitCoApplication } = usePostCoapplicantApplication();
  const [searchParams] = useSearchParams();
  const isCoApplicant = window.location.pathname.includes('coapplicant');
  const [, setApplicationUrl] = useSessionStorage('application_url', '');
  const [readDisclosures, setReadDisclosures] = useState<string[]>([]);
  const { createToast } = useToast();
  const [disableEmail, setDisableEmail] = useState(false);
  const [dealerState, setDealerState] = useState('');
  const [passedEmail, setPassedEmail] = useState('');
  const [paperApplication, setPaperApplication] = useState(false);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    // If the user has read the legal terms, add them to the read disclosures array
    if (legalTermOpen && !readDisclosures.includes(legalTermOpen)) {
      setReadDisclosures([...readDisclosures, legalTermOpen]);
    }
  }, [legalTermOpen, setReadDisclosures]);

  const initialValues: ApplicationFormValues = {
    firstName: searchParams.get('firstname') || '',
    lastName: searchParams.get('lastname') || '',
    streetAddress: '',
    city: '',
    state: undefined,
    county: '',
    zipCode: '',
    phoneNumber: '',
    homeNumber: '',
    emailAddress: passedEmail,
    coApplicant: false,
    ssn: '',
    fullssn: isCoApplicant,
    consentAgreement: !(isCoApplicant || isDealerDevice),
    consentData: !isCoApplicant,
    termsAgreement: !(isCoApplicant || isDealerDevice),
    testingData: '',
    primaryFirstName: primaryNames?.first_name || '',
    primaryLastName: primaryNames?.last_name || '',
    amountRequested: primaryNames?.borrowing_amount.toString(),
    collateral: serialNumber?.serial_number || [],
    paper_application: paperApplication,
  };

  useEffect(() => {
    setApplicationUrl(window.location.href);
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      let parameter = param;

      if (param.indexOf('?')) {
        parameter = param.slice(param.indexOf('?') + 1, param.length);
      }

      if (parameter === 'dealer_id') {
        setDealerID(decodeURI(value));
      }

      if (parameter === 'is_dealer_device') {
        setIsDealerDevice(decodeURI(value) === 'true');
      }

      if (parameter === 'email') {
        setPassedEmail(decodeURI(value));
        setDisableEmail(true);
      }

      if (parameter === 'paper_application') {
        setPaperApplication(value === 'yes' ? true : false);
      }

      if (parameter === 'new_lead') {
        if (value === 'true') {
          setDisableEmail(true);
        }
      }

      if (parameter === 'dealer_state') {
        setDealerState(value);
      }

      if (parameter === 'serial_number') {
        const vehicleDetails = JSON.parse(decodeURI(value));
        const snArray: SerialNumberArray = {
          serial_number: [],
        };

        for (const detail of vehicleDetails) {
          snArray.serial_number.push({
            year: detail.year,
            make: detail.make,
            model: detail.model,
            serial_number: detail.serial_number,
            serial_number_type: detail.serial_number_type,
            serial_number_asset_class: detail.serial_number_asset_class,
            condition: detail.condition,
            price: detail.price,
            nada_msrp_invoice: detail.nada_msrp_invoice,
          });
        }

        setSerialNumber(snArray);
      }

      if (parameter === 'loan_application_id') {
        setPrimaryLoanApplicationID(value);
        setLoanApplicationID(value);
      }
      if (isCoApplicant) {
        if (parameter === '?token') {
          localStorage.setItem('user_token', value);
          const customer_id = getCustomerIdFromToken(value);
          setCustomerID(customer_id);
        }
      }
    }
  }, [searchParams, isCoApplicant, setDealerID, setLoanApplicationID, setCustomerID]);

  const onSubmit = async (values: ApplicationFormValues) => {
    setPending(true);
    if (legalTerms === undefined) {
      setPending(false);
      return;
    }

    if (isCoApplicant) {
      if (!primaryLoanApplicationID) {
        setPending(true);
        return;
      }

      const mappedValues: CoapplicantDetails = {
        loan_application_id: primaryLoanApplicationID,
        customer_details: {
          first_name: values.firstName,
          last_name: values.lastName,
          annual_gross_income: Number(values.grossIncome),
          phone_number_cell: '+1' + values.phoneNumber,
          phone_number_home: values.homeNumber == '' ? null : '+1' + values.homeNumber,
          social_security_number: values.ssn,
          date_of_birth: values.dateOfBirth,
          address: {
            address_lines: [values.streetAddress],
            city: values.city,
            state: values.state?.value,
            county: values.county,
            zip_code: values.zipCode,
          },
          years_in_current_employment: values.yearsInCurrentEmployment,
          years_in_current_address: values.yearsInCurrentResidence,
          residence_type: values.residenceType?.value,
        },
        legal_terms: {
          consent_cra_policy: legalTerms?.find(item => item.document_type === 'CreditReportAuthorization')?.id || '',
          consent_esign_disclosure:
            legalTerms?.find(item => item.document_type === 'ElectronicDisclosuresPolicy')?.id || '',
          consent_terms_and_conditions: legalTerms?.find(item => item.document_type === 'TermsOfUse')?.id || '',
        },
      };

      submitCoApplication(mappedValues, {
        onSuccess: () => {
          if (values.paper_application === false) {
            navigate('/coapp_complete');
            return;
          }

          setIsJoint(true);
          navigate('/processing');
        },
        onError: () => {
          setPending(false);
          navigate('/error');
        },
      });

      return;
    }
    const mappedValues: UserDetails = {
      address: {
        address_lines: [values.streetAddress],
        city: values.city,
        state: values.state?.value,
        zip_code: values.zipCode,
      },
      annual_gross_income: Number(values.grossIncome),
      date_of_birth: values.dateOfBirth,
      dealer_id: dealerID,
      email_address: values.emailAddress,
      first_name: values.firstName,
      last_name: values.lastName,
      legal_terms: {
        consent_cra_policy: legalTerms?.find(item => item.document_type === 'CreditReportAuthorization')?.id || '',
        consent_esign_disclosure:
          legalTerms?.find(item => item.document_type === 'ElectronicDisclosuresPolicy')?.id || '',
        consent_terms_and_conditions: legalTerms?.find(item => item.document_type === 'TermsOfUse')?.id || '',
      },
      co_applicant: null,
      phone_number_cell: '+1' + values.phoneNumber,
      phone_number_home: values.homeNumber == '' ? null : '+1' + values.homeNumber,
      residence_type: values.residenceType?.value,
      serial_number: serialNumber?.serial_number,
      source_of_application: 'dealer',
      user_locale: 'en_US',
      years_in_current_employment: values.yearsInCurrentEmployment,
      years_in_current_address: values.yearsInCurrentResidence,
      social_security_number: values.ssn,
      paper_application: values.paper_application as boolean,
    };

    if (values.coApplicant) {
      mappedValues.co_applicant = {
        first_name: values.coapplicantFirstName,
        last_name: values.coapplicantLastName,
        email_address: values.coapplicantEmailAddress,
      };
    }

    setUserDetails(mappedValues);
    submitApplication(mappedValues, {
      onSuccess: data => {
        localStorage.setItem('user_token', data.primary_token);
        setLoanApplicationID(data.loan_application_id);
        setCustomerID(data.customer_id);
        setCoapplicantID(data.co_applicant_id);

        if (values.coApplicant) {
          navigate('/waiting_for_coapplicant');
          return;
        }

        if (values.paper_application === false) {
          navigate('/email_sent');
          return;
        }

        navigate('/processing');
      },
      onError: () => {
        setPending(false);
        createToast({
          title: formatMessage(messages.dealerError),
          status: 'error',
        });
      },
    });
  };

  const handleTestingDataChange = (
    setValues: (
      values: SetStateAction<ApplicationFormValues>,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<ApplicationFormValues>>,
    value?: string,
  ) => {
    if (value !== undefined) {
      const selectedTestingDataValues = TuTestCases[value];
      selectedTestingDataValues.testingData = value;
      setValues(selectedTestingDataValues);
    } else {
      setValues(initialValues);
    }
  };

  return (
    <>
      <Body fullWidth>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={applicationSchema}
          enableReinitialize
          validateOnMount>
          {({ values, errors, handleBlur, touched, handleSubmit, handleChange, setValues, isValid }) => (
            <Form>
              {brandName ? (
                <div className="dealer-branding">
                  <h2>{brandName?.display_name}</h2>
                </div>
              ) : null}
              <div className="form-header">
                <h2>{formatMessage(messages.title)}</h2>
              </div>
              <p>{isCoApplicant && formatMessage(messages.coapplicantSubtitle)}</p>
              <p className="notice">{formatMessage(messages.notice)}</p>
              {/* TEST DATA */}
              {(import.meta.env.MODE === 'DEV' || import.meta.env.MODE === 'development') && (
                <fieldset className="fieldset">
                  <SelectInput
                    label={'Testing Data'}
                    name={'testingData'}
                    options={['', ...Object.keys(TuTestCases)]}
                    onChange={e => handleTestingDataChange(setValues, e as string)}
                    value={values.testingData}
                  />
                </fieldset>
              )}
              {/* coapplicant section */}
              {isCoApplicant && (
                <fieldset className="fieldset">
                  <h3>{formatMessage(messages.coapplicantFormTitle)}</h3>
                  <Input
                    label={formatMessage(messages.primaryFirstName)}
                    placeholder={formatMessage(messages.primaryFirstName)}
                    name="primaryFirstName"
                    onChange={() => {}}
                    value={values.primaryFirstName}
                    error={undefined}
                    disabled
                  />
                  <Input
                    label={formatMessage(messages.primaryLastName)}
                    placeholder={formatMessage(messages.primaryLastName)}
                    name="primaryLastName"
                    onChange={() => {}}
                    value={values.primaryLastName}
                    error={undefined}
                    disabled
                  />
                </fieldset>
              )}
              {/* end coapplicant section */}

              <fieldset className={`fieldset ${isCoApplicant && 'border-top'}`}>
                <h3>{formatMessage(messages.applicantFormTitle)}</h3>
                <Input
                  label={formatMessage(messages.firstName)}
                  placeholder={formatMessage(messages.firstName)}
                  name="firstName"
                  onChange={handleChange}
                  value={values.firstName}
                  error={!!touched.firstName && errors.firstName ? errors.firstName : undefined}
                />
                <Input
                  label={formatMessage(messages.lastName)}
                  placeholder={formatMessage(messages.lastName)}
                  name="lastName"
                  onChange={handleChange}
                  value={values.lastName}
                  error={errors.lastName && !!touched.lastName ? errors.lastName : undefined}
                />
                <Input
                  label={formatMessage(messages.streetAddress)}
                  placeholder={formatMessage(messages.streetAddress)}
                  name="streetAddress"
                  onChange={handleChange}
                  value={values.streetAddress}
                  error={errors.streetAddress && !!touched.streetAddress ? errors.streetAddress : undefined}
                />
                <Input
                  label={formatMessage(messages.city)}
                  placeholder={formatMessage(messages.city)}
                  name="city"
                  onChange={handleChange}
                  value={values.city}
                  error={errors.city && !!touched.city ? errors.city : undefined}
                />
                <SelectInput
                  label={formatMessage(messages.state)}
                  placeholder={formatMessage(messages.state)}
                  onChange={handleChange}
                  value={values.state}
                  error={errors.state && !!touched.state ? errors.state : undefined}
                  name="state"
                  options={stateCodeMapping}
                />
                <Input
                  label={formatMessage(messages.county)}
                  placeholder={formatMessage(messages.county)}
                  name="county"
                  onChange={handleChange}
                  value={values.county}
                  error={errors.county && !!touched.county ? errors.county : undefined}
                  disabled={dealerState !== 'NV'}
                />
                <Input
                  label={formatMessage(messages.zipCode)}
                  placeholder={formatMessage(messages.zipCode)}
                  name="zipCode"
                  onChange={handleChange}
                  value={values.zipCode}
                  error={errors.zipCode && !!touched.zipCode ? errors.zipCode : undefined}
                />
                <Input
                  label={formatMessage(messages.phoneNumber)}
                  placeholder={formatMessage(messages.phoneNumber)}
                  name="phoneNumber"
                  type={'tel'}
                  onChange={handleChange}
                  value={values.phoneNumber}
                  error={errors.phoneNumber && !!touched.phoneNumber ? errors.phoneNumber : undefined}
                  disclaimer={
                    !isCoApplicant && isDealerDevice ? formatMessage(messages.phoneNumberCoAuthorization) : undefined
                  }
                />
                <Input
                  type="tel"
                  label={formatMessage(messages.homeNumber)}
                  placeholder={formatMessage(messages.homeNumber)}
                  name="homeNumber"
                  onChange={handleChange}
                  value={values.homeNumber}
                  error={errors.homeNumber && !!touched.homeNumber ? errors.homeNumber : undefined}
                  required={false}
                />
              </fieldset>
              <fieldset className="fieldset">
                <Input
                  label={formatMessage(messages.emailAddress)}
                  placeholder={formatMessage(messages.emailAddress)}
                  name="emailAddress"
                  onChange={handleChange}
                  value={values.emailAddress}
                  error={errors.emailAddress && !!touched.emailAddress ? errors.emailAddress : undefined}
                  disabled={disableEmail}
                />
                <Input
                  type="date"
                  label={formatMessage(messages.dateOfBirth)}
                  placeholder={formatMessage(messages.dateOfBirth)}
                  name="dateOfBirth"
                  onChange={handleChange}
                  value={values.dateOfBirth}
                  error={errors.dateOfBirth && !!touched.dateOfBirth ? errors.dateOfBirth : undefined}
                />
                <SelectInput
                  label={formatMessage(messages.residenceType)}
                  placeholder={formatMessage(messages.residenceType)}
                  onChange={handleChange}
                  value={values.residenceType || ''}
                  error={errors.residenceType && !!touched.residenceType ? errors.residenceType : undefined}
                  name="residenceType"
                  options={residenceTypeDisplayValues}
                />
                <Input
                  type="number"
                  label={formatMessage(messages.yearsInCurrentResidence)}
                  placeholder={formatMessage(messages.yearsInCurrentResidence)}
                  name="yearsInCurrentResidence"
                  onChange={handleChange}
                  value={values.yearsInCurrentResidence}
                  error={
                    errors.yearsInCurrentResidence && !!touched.yearsInCurrentResidence
                      ? errors.yearsInCurrentResidence
                      : undefined
                  }
                />
                <Input
                  type="currency"
                  label={formatMessage(messages.grossIncome)}
                  placeholder={formatMessage(messages.grossIncome)}
                  name="grossIncome"
                  onChange={handleChange}
                  value={values.grossIncome}
                  error={errors.grossIncome && !!touched.grossIncome ? errors.grossIncome : undefined}
                  currency
                  onClick={(e: ChangeEvent<HTMLInputElement>) => e.target.select()}
                  disclaimer={
                    !isCoApplicant
                      ? formatMessage(messages.grossIncomeDetails)
                      : formatMessage(messages.grossIncomeCoDetails)
                  }
                />
                <Input
                  type="number"
                  label={formatMessage(messages.yearsInCurrentEmployment)}
                  placeholder={formatMessage(messages.yearsInCurrentEmployment)}
                  name="yearsInCurrentEmployment"
                  onChange={handleChange}
                  value={values.yearsInCurrentEmployment}
                  error={
                    errors.yearsInCurrentEmployment && !!touched.yearsInCurrentEmployment
                      ? errors.yearsInCurrentEmployment
                      : undefined
                  }
                />
                {!isCoApplicant ? (
                  <Input
                    label={formatMessage(messages.ssn)}
                    placeholder={formatMessage(messages.ssn)}
                    name="ssn"
                    onChange={handleChange}
                    value={values.ssn}
                    error={errors.ssn && !!touched.ssn ? errors.ssn : undefined}
                    required
                  />
                ) : (
                  <>
                    <Input
                      label={formatMessage(messages.amountRequested)}
                      placeholder={formatMessage(messages.amountRequested)}
                      type="currency"
                      name="amountRequested"
                      onChange={handleChange}
                      value={values.amountRequested}
                      error={undefined}
                      required
                      currency
                      disabled={isCoApplicant}
                    />
                    <Input
                      label={formatMessage(messages.fullSsn)}
                      placeholder={formatMessage(messages.fullSsn)}
                      name="ssn"
                      onChange={handleChange}
                      value={values.ssn}
                      error={errors.ssn && !!touched.ssn ? errors.ssn : undefined}
                      onBlur={handleBlur}
                    />
                  </>
                )}
              </fieldset>
              {!isCoApplicant && (
                <>
                  <Checkbox
                    label={formatMessage(messages.coApplicant)}
                    name="coApplicant"
                    onChange={handleChange}
                    value={values.coApplicant}
                    error={errors.coApplicant}
                    required={false}
                  />
                  <fieldset className="fieldset">
                    {values.coApplicant && (
                      <>
                        <Input
                          label={formatMessage(messages.firstName)}
                          placeholder={formatMessage(messages.firstName)}
                          name="coapplicantFirstName"
                          onChange={handleChange}
                          value={values.coapplicantFirstName}
                          error={
                            !!touched.coapplicantFirstName && errors.coapplicantFirstName
                              ? errors.coapplicantFirstName
                              : undefined
                          }
                          onBlur={handleBlur}
                        />
                        <Input
                          label={formatMessage(messages.lastName)}
                          placeholder={formatMessage(messages.lastName)}
                          name="coapplicantLastName"
                          onChange={handleChange}
                          value={values.coapplicantLastName}
                          error={
                            errors.coapplicantLastName && !!touched.coapplicantLastName
                              ? errors.coapplicantLastName
                              : undefined
                          }
                        />
                        <Input
                          label={formatMessage(messages.emailAddress)}
                          placeholder={formatMessage(messages.emailAddress)}
                          name="coapplicantEmailAddress"
                          onChange={handleChange}
                          value={values.coapplicantEmailAddress}
                          error={
                            errors.coapplicantEmailAddress && !!touched.coapplicantEmailAddress
                              ? errors.coapplicantEmailAddress
                              : undefined
                          }
                        />
                      </>
                    )}
                  </fieldset>
                </>
              )}
              {isDealerDevice && (
                <>
                  <fieldset className="fieldset">
                    <h3>{formatMessage(messages.consentTitle)}</h3>
                    <span className="esign-text">
                      <p>{formatMessage(messages.esignPretext)}</p>
                      <br />
                      {legalTerms !== undefined && (
                        <ReactMarkdown>
                          {(
                            legalTerms.find(item => item.document_type === 'ElectronicDisclosuresPolicy')
                              ?.file_contents || ''
                          ).substring(35, 400) + '...'}
                        </ReactMarkdown>
                      )}
                      <a
                        className="electronic-disclosure"
                        onClick={() => {
                          setLegalTermOpen('ElectronicDisclosuresPolicy');
                          setModalIsOpen(true);
                        }}
                        target="_blank"
                        rel="noreferrer">
                        {formatMessage(messages.readMore)}
                      </a>
                    </span>
                    <Checkbox
                      label={formatMessage(messages.consentAgreement)}
                      name="consentAgreement"
                      onChange={handleChange}
                      disabled={!readDisclosures.includes('ElectronicDisclosuresPolicy')}
                      value={values.consentAgreement}
                      error={errors.consentAgreement}
                    />
                  </fieldset>
                  <fieldset className="fieldset border-top fullWidth">
                    <h3>{formatMessage(messages.creditReportAuthorization)}</h3>
                    <p>{formatMessage(messages.termsDetails)}</p>
                    <ul>
                      <li>
                        {formatMessage(messages.termsDetails.items.item1, {
                          url: (
                            <a
                              onClick={() => {
                                setLegalTermOpen('CreditReportAuthorization');
                                setModalIsOpen(true);
                              }}>
                              {formatMessage(messages.termsDetails.items.item1Title)}
                            </a>
                          ),
                        })}
                      </li>
                    </ul>
                    <Checkbox
                      label={formatMessage(messages.termsAgreement)}
                      name="termsAgreement"
                      onChange={handleChange}
                      disabled={!readDisclosures.includes('CreditReportAuthorization')}
                      value={values.termsAgreement}
                      error={errors.termsAgreement}
                    />
                  </fieldset>
                </>
              )}
              {isCoApplicant && (
                <>
                  <fieldset className={'fieldset'}>
                    <h3>{formatMessage(messages.consentTitle)}</h3>
                    <span className="esign-text">
                      {legalTerms !== undefined && (
                        <ReactMarkdown>
                          {(
                            legalTerms.find(item => item.document_type === 'ElectronicDisclosuresPolicy')
                              ?.file_contents || ''
                          ).substring(35, 400) + '...'}
                        </ReactMarkdown>
                      )}
                      <a
                        className="electronic-disclosure"
                        onClick={() => {
                          setLegalTermOpen('ElectronicDisclosuresPolicy');
                          setModalIsOpen(true);
                        }}
                        target="_blank"
                        rel="noreferrer">
                        {formatMessage(messages.readMore)}
                      </a>
                    </span>
                    <Checkbox
                      label={formatMessage(messages.consentAgreement)}
                      name="consentAgreement"
                      disabled={!readDisclosures.includes('ElectronicDisclosuresPolicy')}
                      onChange={handleChange}
                      value={values.consentAgreement}
                      error={errors.consentAgreement}
                    />
                    <Checkbox
                      label={formatMessage(messages.consentData)}
                      name="consentData"
                      onChange={handleChange}
                      value={values.consentData}
                      error={errors.consentData}
                    />
                  </fieldset>
                  <fieldset className="fieldset border-top fullWidth">
                    <h3>{formatMessage(messages.creditReportAuthorization)}</h3>
                    <p>{formatMessage(messages.termsDetails)}</p>
                    <p>
                      {formatMessage(messages.termsDetails.items.item1, {
                        url: (
                          <a
                            onClick={() => {
                              setLegalTermOpen('CreditReportAuthorization');
                              setModalIsOpen(true);
                            }}>
                            {formatMessage(messages.termsDetails.items.item1Title)}
                          </a>
                        ),
                      })}
                    </p>
                    <Checkbox
                      label={formatMessage(messages.termsAgreement)}
                      name="termsAgreement"
                      onChange={handleChange}
                      disabled={!readDisclosures.includes('CreditReportAuthorization')}
                      value={values.termsAgreement}
                      error={errors.termsAgreement}
                    />
                  </fieldset>
                </>
              )}
              <ButtonGroup>
                <Button
                  type="submit"
                  variant="secondary"
                  onClick={handleSubmit}
                  pending={pending}
                  testId="application-submit-button"
                  fullWidth={false}
                  disabled={!isValid}
                  name="prequalify button">
                  {formatMessage(messages.submit)}
                </Button>
              </ButtonGroup>
              <p className="centered-text">
                {!dealerID && <p className={'error'}>{formatMessage(messages.dealerError)}</p>}
                <i>{formatMessage(messages.softPullMessage)}</i>
              </p>
            </Form>
          )}
        </Formik>
        {modalIsOpen && legalTerms !== undefined && (
          <Modal onClose={() => setModalIsOpen(false)} maxHeight title={modalNames[legalTermOpen || 'TermsOfUse']}>
            {legalTermOpen === 'ElectronicDisclosuresPolicy' && (
              <ReactMarkdown>
                {(
                  legalTerms.find(item => item.document_type === 'ElectronicDisclosuresPolicy')?.file_contents || ''
                ).substring(35)}
              </ReactMarkdown>
            )}
            {legalTermOpen !== 'ElectronicDisclosuresPolicy' && (
              <div className="pdf-container">
                <embed
                  type={'application/pdf'}
                  src={legalTerms.find(item => item.document_type === legalTermOpen)?.url}
                  width="100%"
                  height="100%"
                />
              </div>
            )}
          </Modal>
        )}
      </Body>
    </>
  );
};

export default ApplicationForm;
