import { useCallback } from 'react';
import { useToast as useToastOriginal, UseToastOptions } from '@chakra-ui/react';

interface ToastProps extends UseToastOptions {
  title?: string;
  description?: string;
  status?: 'info' | 'warning' | 'success' | 'error';
  duration?: number;
  isClosable?: boolean;
}

const useToast = () => {
  const toast = useToastOriginal();

  const createToast = useCallback(
    ({ title, description, status, duration, isClosable }: ToastProps) => {
      toast({
        title,
        description,
        status,
        duration,
        isClosable,
      });
    },
    [toast],
  );

  return { createToast };
};

export default useToast;
