export interface NavLinkProp {
  label: string;
  url: string;
}

export interface LoginCredentials {
  email_address: string;
  password: string;
}

export interface LoginResponse {
  last_signed_in: string;
  token: string;
}

export interface PasswordResetRequest {
  new_password: string;
}

export interface AuthCodeValues {
  authCode0?: string;
  authCode1?: string;
  authCode2?: string;
  authCode3?: string;
  authCode4?: string;
  authCode5?: string;
}

export type VerificationCode = string;
export type VerificationMethod = 'Email' | 'SMS';

export interface SendCodeEmailRequest {
  email: string;
  method: VerificationMethod;
}

export interface SendCodeConsumerRequest {
  customer_id: string;
  loan_application_id: string;
  method: VerificationMethod;
}

export interface PostVerificationRequest {
  email: string;
  method: VerificationMethod;
  verification_code: string;
}

export interface PostVerificationConsumerRequest {
  customer_id: string;
  loan_application_id: string;
  method: VerificationMethod;
  verification_code: string;
}

export type ConsumerVerificationStatus = 'Verified' | 'Blocked';

export interface PostVerificationConsumerResponse {
  application_verification_status: ConsumerVerificationStatus;
  token: string;
}

export interface DealInformation {
  dealer_id: string;
  additions: KVPair[] | [];
  deductions: KVPair[] | [];
  backend_products: KVPair[] | [];
  accessories: AccessoryDetail[] | [];
  totals: TotalsProps;
}

export type TotalsProps = {
  base_accessories?: number;
  additions?: number;
  backend_products?: number;
  deductions?: number;
  approved_amount?: number;
  base_product_total?: number;
  total_requested?: number;
  isFrontendLTVExceed?: boolean;
  isBackendLTVExceed?: boolean;
  isBackendValueExceed?: boolean;
  frontendTotalValue?: number;
  backendTotalValue?: number;
  basePrice?: number;
  frontendLtvPercentage?: number;
  backendLtvPercentage?: number;
  maxFrontEndPercentNewProduct?: number;
  maxBackendPercentNewProduct?: number;
  maxBackendAmountNewProduct?: number;
  maxFrontEndPercentUsedProduct?: number;
  maxBackendPercentUsedProduct?: number;
  maxBackendAmountUsedProduct?: number;
  maxOverallNewProduct?: number;
  maxOverallUsedProduct?: number;
  maxBackendOverall?: number;
  backendPriceLimit?: number;
};

export interface DealStatus {
  applicants: [
    {
      cell_phone_number: string;
      first_name: string;
      idv_complete: true;
      is_primary: true;
      last_name: string;
      poi_complete: true;
      state: string;
      zip_code: string;
    },
  ];
  dealership_name: string;
  delivery_date: string;
  make: string;
  model: string;
  prequal_date: string;
  status: string;
  prequal_amount: number;
}

export interface KVPair {
  key: string;
  value: number | undefined;
  name?: string;
  term?: number;
}

export type AccessoryDetail = {
  name: string;
  price: number | undefined;
  is_financeable: boolean;
};

export interface SelectOption {
  value: string;
  label: string;
}

export enum OEMType {
  Brunswick = 'brunswick',
  KTM = 'ktm',
  EZGO = 'ezgo',
  CUSTOM = 'custom',
}

export interface LienInformationRequest {
  deduction: KVPair[] | [];
}

export type FlowTemplatePostData = {
  promotional_logo: string;
  warranty_link?: string;
  warranty_text?: string;
  name: string;
  base_type: string;
  unit_identifier: string;
  seller_purchase_price_type: string;
  theme_colour: string;
  primary_logo: string;
  stock_image: string;
  accessory_examples: string[];
  participation_fee: boolean;
  dealer_fee: boolean;
  consumer_only: boolean;
};

export type FlowTemplateData = {
  promotional_logo: string;
  warranty_link?: string;
  warranty_text?: string;
  id: string;
  name: string;
  base_type: string;
  unit_identifier: string;
  seller_purchase_price_type: string;
  theme_colour: string;
  primary_logo: string;
  stock_image: string;
  accessory_examples: string[];
  participation_fee: boolean;
  dealer_fee: boolean;
  consumer_only: boolean;
  created_at: string;
  updated_at: string;
};
