import React from 'react';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { PricingStatus } from './queries/useGetPricingStatus';
import { UserDetails, FlowTemplate } from './types';
import { OEMType as EOEMType } from '@luna-protocol/core/src/types.ts';

type AppContextProviderProps = {
  children: React.ReactNode;
};

type ContextDefaults =
  | {
      userDetails?: UserDetails;
      setUserDetails: React.Dispatch<React.SetStateAction<UserDetails>>;
      dealerID?: string;
      setDealerID: React.Dispatch<React.SetStateAction<string>>;
      isDealerDevice?: boolean;
      setIsDealerDevice: React.Dispatch<React.SetStateAction<boolean>>;
      loanApplicationID?: string | undefined;
      setLoanApplicationID: React.Dispatch<React.SetStateAction<string | undefined>>;
      customerID?: string | undefined;
      setCustomerID: React.Dispatch<React.SetStateAction<string | undefined>>;
      coapplicantID?: string | undefined;
      setCoapplicantID: React.Dispatch<React.SetStateAction<string | undefined>>;
      pricingStatus?: PricingStatus;
      setPricingStatus: React.Dispatch<React.SetStateAction<PricingStatus | undefined>>;
      OEMType?: EOEMType;
      setOEMType: React.Dispatch<React.SetStateAction<EOEMType>>;
      setDealerFlowTemplate: React.Dispatch<React.SetStateAction<FlowTemplate | undefined>>;
      dealerFlowTemplate: FlowTemplate | undefined;
      isJoint?: boolean;
      setIsJoint: React.Dispatch<React.SetStateAction<boolean>>;
    }
  | Record<string, never>;

export const AppContext = React.createContext({} as ContextDefaults);

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const [userDetails, setUserDetails] = useSessionStorage<UserDetails>('userDetails', {} as UserDetails);
  // local storage is used here, as this is more of a global setting
  const [dealerID, setDealerID] = useLocalStorage<string>('dealer_id', '');
  const [loanApplicationID, setLoanApplicationID] = useSessionStorage<string | undefined>(
    'loanApplicationID',
    undefined,
  );
  const [customerID, setCustomerID] = useSessionStorage<string | undefined>('customerID', undefined);
  const [coapplicantID, setCoapplicantID] = useSessionStorage<string | undefined>('coapplicantID', undefined);
  const [pricingStatus, setPricingStatus] = useSessionStorage<PricingStatus | undefined>('pricingStatus', undefined);
  const [isDealerDevice, setIsDealerDevice] = useSessionStorage<boolean>('isDealerDevice', false);
  const [OEMType, setOEMType] = useSessionStorage<EOEMType>('OEMType', EOEMType.Brunswick);
  const [dealerFlowTemplate, setDealerFlowTemplate] = useLocalStorage<FlowTemplate | undefined>(
    'flow_template',
    undefined,
  );
  const [isJoint, setIsJoint] = useSessionStorage<boolean>('isJoint', false);

  return (
    <AppContext.Provider
      value={{
        userDetails,
        setUserDetails,
        dealerID,
        setDealerID,
        loanApplicationID,
        setLoanApplicationID,
        customerID,
        setCustomerID,
        coapplicantID,
        setCoapplicantID,
        pricingStatus,
        setPricingStatus,
        isDealerDevice,
        setIsDealerDevice,
        OEMType,
        setOEMType,
        dealerFlowTemplate,
        setDealerFlowTemplate,
        isJoint,
        setIsJoint,
      }}>
      {children}
    </AppContext.Provider>
  );
};
