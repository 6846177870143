import { useIntl } from 'react-intl';
import { useContext } from 'react';
import { AppContext } from '../../AppContext.tsx';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import messages from './UnderReview.messages';
import './UnderReview.scss';

const UnderReview = () => {
  const { formatMessage } = useIntl();
  const { isJoint } = useContext(AppContext);

  return (
    <>
      <Banner>{formatMessage(isJoint ? messages.title_coapp : messages.title_single)} </Banner>
      <Body>
        <p>{formatMessage(messages.detail1)}</p>
        {!isJoint && <p id="co-app-text">{formatMessage(messages.detail_single)}</p>}
      </Body>
    </>
  );
};

export default UnderReview;
