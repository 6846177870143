import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'pending.title',
    defaultMessage: 'Thank You',
  },
  detail1: {
    id: 'pending.detail1',
    defaultMessage:
      'Your pre-qualification in under review. Once a decision has been reached, you will be notified via email.',
  },
  returnButton: {
    id: 'pending.returnButton',
    defaultMessage: 'Return Home',
  },
});

export default messages;
