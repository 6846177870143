const getBaseApi = () => {
  switch (import.meta.env.MODE) {
    case 'LOCAL':
      /**
       * in the case of building/running a LOCAL docker container
       * so long as the docker container is running on the same docker network
       * as the containers running in docker-compose, you can simply use localhost
       */
      return `http://localhost:3030/v1`;
    case 'STAGING':
      return `https://pwr-stage-api.aquafinance.com/v1`;
    case 'DEV':
      return `https://wfapi.dev-luna-aqua-middleware.com/v1`;
    case 'PRODUCTION':
      return `https://powerapi.aquafinance.com/v1`;
    default:
      return `https://wfapi.dev-luna-aqua-middleware.com/v1`;
  }
};

export default getBaseApi;
