import * as Yup from 'yup';

const minAge = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

const integerValidation = (value: number) => {
  if (!Number.isInteger(value) || String(value).includes('.')) {
    return false;
  }
  return true;
};

export const applicationSchema = Yup.object({
  firstName: Yup.string().required('Please provide a first name'),
  lastName: Yup.string().required('Please provide a last name'),
  streetAddress: Yup.string().required('Please provide a street address'),
  city: Yup.string().required('Please provide a city'),
  state: Yup.object().required('Please provide a state'),
  zipCode: Yup.string()
    .required('Please provide a zip code')
    .test('len', 'Must be exactly 5 or 10 characters', val => val?.length === 5 || val?.length === 10),
  phoneNumber: Yup.string()
    .test('not-composed-of-one-number-only', 'Invalid cell phone number', value =>
      value ? !/\b(\d)\1+\b/.test(value.replace(/\D/g, '')) : true,
    )
    .test('len', 'Must be exactly 10 characters', val => val?.length === 10)
    .matches(/^\d+$/, 'The field should have digits only')
    .required('Please provide a cell phone number'),
  homeNumber: Yup.string().notRequired(),
  emailAddress: Yup.string().email('Invalid email address').required('Please provide an email address'),
  residenceType: Yup.object().required('Please provide a residence type'),
  grossIncome: Yup.string()
    .required('Please provide a gross annual income')
    .matches(/^\d+$/, 'The field should have digits only')
    .test('positive', 'Invalid gross annual income', val => Number(val) > 0),
  coApplicant: Yup.boolean().notRequired(),
  fullssn: Yup.boolean(),
  ssn: Yup.string()
    // .test('is-not-empty', 'The field should have digits only', value => !value || /^\d+$/.test(value))
    .when('fullssn', {
      is: true,
      then: (schema: Yup.StringSchema<string | undefined>) =>
        schema
          .test('len', 'Must be exactly 9 characters', val => !val || val.length === 9)
          .required('Please provide a social security number'),
      otherwise: (schema: Yup.StringSchema<string | undefined>) =>
        schema
          .test('len', 'Must be exactly 4 characters', val => !val || val.length === 4)
          .required('Please provide the last four digits of your SSN'),
    }),
  consentAgreement: Yup.boolean().oneOf([true], 'Field must be checked'),
  consentData: Yup.boolean().oneOf([true], 'Field must be checked'),
  termsAgreement: Yup.boolean().oneOf([true], 'Field must be checked'),
  yearsInCurrentResidence: Yup.number()
    .required('Please provide years in current resident')
    .min(0)
    .max(99, 'Must be 2 digits or less')
    .test('integer', 'Please enter a whole number without decimals', value => integerValidation(value)),
  yearsInCurrentEmployment: Yup.number()
    .required('Please provide years in current employment')
    .min(0)
    .max(99, 'Must be 2 digits or less')
    .test('integer', 'Please enter a whole number without decimals', value => integerValidation(value)),
  dateOfBirth: Yup.date()
    .required('Please provide your date of birth')
    .min(new Date(1900, 0, 1), 'Invalid date')
    .max(minAge(), 'Invalid date'),
  collateral: Yup.array().of(
    Yup.object().shape({
      make: Yup.string().notRequired(),
      model: Yup.string().notRequired(),
      condition: Yup.string().oneOf(['new', 'used']).notRequired(),
      price: Yup.number().notRequired(),
    }),
  ),
  coapplicantEmailAddress: Yup.string().notRequired().email('Invalid email address'),
  coappFirstName: Yup.string().notRequired(),
  coappLastName: Yup.string().notRequired(),
});
